// source : https://joseantonio.dev/ejemplo-http-interceptor-con-ionic-4-y-angular-7/ 
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from} from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  isLoading: boolean = false;
  constructor(
    public loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.presentLoading();

    const token:string = localStorage.getItem('token');

    let request_clone = request;
    if(token) {
      request_clone = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ token }`
        }
      });
    }
    return next.handle(request_clone).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Cerramos el loading en el fin de la llamada
          this.dismissLoading();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.dismissLoading();
        // Presentamos un toast con el mensaje de error
        this.presentErrorToast('Ups, ha habido un problema');
        return throwError(error);
      })
    );
  }
  // Presenta el toast con el error
  async presentErrorToast(msg:any) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',
      cssClass: 'toast',
      showCloseButton: true,
      closeButtonText: "OK",
    });
    toast.present();
  }
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: 'Cargando',
      spinner: 'crescent',
      duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log());
        }
      });
    });
  }
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }
}