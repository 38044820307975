import * as tslib_1 from "tslib";
// source : https://joseantonio.dev/ejemplo-http-interceptor-con-ionic-4-y-angular-7/ 
import { HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadingController, ToastController } from '@ionic/angular';
export class HttpConfigInterceptor {
    constructor(loadingCtrl, toastCtrl) {
        this.loadingCtrl = loadingCtrl;
        this.toastCtrl = toastCtrl;
        this.isLoading = false;
    }
    intercept(request, next) {
        this.presentLoading();
        const token = localStorage.getItem('token');
        let request_clone = request;
        if (token) {
            request_clone = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request_clone).pipe(map((event) => {
            if (event instanceof HttpResponse) {
                // Cerramos el loading en el fin de la llamada
                this.dismissLoading();
            }
            return event;
        }), catchError((error) => {
            console.error(error);
            this.dismissLoading();
            // Presentamos un toast con el mensaje de error
            this.presentErrorToast('Ups, ha habido un problema');
            return throwError(error);
        }));
    }
    // Presenta el toast con el error
    presentErrorToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastCtrl.create({
                message: msg,
                duration: 3000,
                position: 'top',
                color: 'danger',
                cssClass: 'toast',
                showCloseButton: true,
                closeButtonText: "OK",
            });
            toast.present();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            return yield this.loadingCtrl.create({
                message: 'Cargando',
                spinner: 'crescent',
                duration: 5000,
            }).then(a => {
                a.present().then(() => {
                    if (!this.isLoading) {
                        a.dismiss().then(() => console.log());
                    }
                });
            });
        });
    }
    dismissLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = false;
            return yield this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
        });
    }
}
