import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../services/api.service';
import { AlertasService } from '../services/alertas.service';
import { faCoffee, faHeadset, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import * as configs from '../configurable.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alerta-patente',
  templateUrl: './alerta-patente.component.html',
  styleUrls: ['./alerta-patente.component.scss'],
})
export class AlertaPatenteComponent implements OnInit {
  public telefono2: string = environment.telefono;
  params;
  faCoffee = faCoffee;
  faHeadset = faHeadset;
  faPhoneAlt = faPhoneAlt ; 
  telefono:string = "";
  constructor(
    private modal: ModalController,
    public api:ApiService,
    private alerta:AlertasService
  ) {
    this.telefono = environment.contactdata.telefono;
  }

  ngOnInit() {
    console.log(this.params);
  }
  cancelar(){
    this.modal.dismiss();
  }

  llamar(){
    window.open("tel:"+this.telefono, "_self");
  }
  solicitar(){
    this.modal.dismiss();
    this.api.solicitarAsistencia(this.params).subscribe(data => {
      if (data['error_code'] === 0 && data['status_code'] === 1) {
        return this.alerta.solicitudFormulario(true, data);
      } else {
        return this.alerta.solicitudFormulario(false, data);
      }
    });
  }
}
