<ion-header>
  <ion-toolbar class="toolbar-mapa" color="primary">
    <ion-title>Ubicación</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="modalDismissClose()" color="secondary">
        Cerrar
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="confirmar-mapa" (click)="modalDismiss()" color="">
        Confirmar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
<input type="text" id="pac-input" placeholder="Escribe tu dirección">
  <div id="map"></div>
  <div class="info">
    {{direccion}}
  </div>
</ion-content>

