/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-maps.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./google-maps.component";
import * as i5 from "../services/alertas.service";
var styles_GoogleMapsComponent = [i0.styles];
var RenderType_GoogleMapsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleMapsComponent, data: {} });
export { RenderType_GoogleMapsComponent as RenderType_GoogleMapsComponent };
export function View_GoogleMapsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 14, "ion-toolbar", [["class", "toolbar-mapa"], ["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Ubicaci\u00F3n"])), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-button", [["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalDismissClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Cerrar "])), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(13, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "ion-button", [["class", "confirmar-mapa"], ["color", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Confirmar "])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(18, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 0, "input", [["id", "pac-input"], ["placeholder", "Escribe tu direcci\u00F3n"], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, 0, 0, "div", [["id", "map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, 0, 1, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "secondary"; _ck(_v, 10, 0, currVal_1); var currVal_2 = ""; _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.direccion; _ck(_v, 22, 0, currVal_3); }); }
export function View_GoogleMapsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "google-map", [], null, null, null, View_GoogleMapsComponent_0, RenderType_GoogleMapsComponent)), i1.ɵdid(1, 114688, null, 0, i4.GoogleMapsComponent, [i5.AlertasService, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleMapsComponentNgFactory = i1.ɵccf("google-map", i4.GoogleMapsComponent, View_GoogleMapsComponent_Host_0, { destino: "destino", coord_origen: "coord_origen" }, {}, []);
export { GoogleMapsComponentNgFactory as GoogleMapsComponentNgFactory };
