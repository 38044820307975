import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './httpConfig.interceptor';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { AlertaPatenteComponent } from './alerta-patente/alerta-patente.component';
import { NgKnifeModule } from 'ng-knife';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    GoogleMapsComponent,
    AlertaPatenteComponent
  ],
  entryComponents: [
    GoogleMapsComponent,
    AlertaPatenteComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      hardwareBackButton: false
    }),
    NgKnifeModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js',
      { enabled: environment.production }),
    DeviceDetectorModule.forRoot(),
    RecaptchaV3Module,
    
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.gcaptcha },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
