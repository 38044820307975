import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public telefono: string = environment.telefono;

  constructor(
    private platform: Platform,
    private swUpd: SwUpdate,
    private title: Title
  ) {
    this.title.setTitle(environment.nombre_aplicacion);
    this.initializeApp();
  }

  initializeApp() {
    document.addEventListener("backbutton", function (e) {
      e.preventDefault();
      console.log("hello");
    }, false);
    this.platform.ready().then(() => {
      // cargar scripts
      this.loadScripts(); 
      if (this.swUpd.available) {
        this.swUpd.available.subscribe(r => {
          console.warn("Update detectada... - actualizando");
          window.location.reload();
        });
      }
    });
  }

  loadScripts(){
    const scripts = [
      "https://maps.googleapis.com/maps/api/js?key="
    ];
    var node = document.createElement('script')
    node.type = 'text/javascript';
    node.async = false; 
    node.charset = 'utf-8';

    node.src = `${scripts[0]}${environment.gmaps.token}${environment.gmaps.options}`;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
