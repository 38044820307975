import { Injectable } from '@angular/core';
import {AES, enc} from 'crypto-js';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  ssp:string;
  constructor() { 
    this.ssp = environment._ssp;
  }

  enc(phrase:string){
    return AES.encrypt(phrase, this.ssp);
  }

  dec(phrase:string){
    return AES.decrypt(phrase, this.ssp).toString(enc.Utf8).trim();
  }
  
  encJson(obj:any){
    let objeto = JSON.stringify(obj);
    return this.enc(objeto);
  }

  decJson(obj:string){
    let objeto = this.dec(obj);
    return JSON.parse(objeto);
  }
}